import React from "react"

export default function Section3() {
  return <div className="lg:flex w-full p-0 lg:py-20">
        <div className="lg:w-1/2 md:w-full self-center p-10 lg:px-0 lg:py-20">
            <p className="text-3xl text-black text-aileron-bold">
                Empowering Communities for Effective Collaboration through our Extensive Range of Feature
            </p>
            <div className="divider bg-accent my-8"></div>
            <p className="text-xl leading-loose text-gray-800 my-5">
                Ciergio aims to simplify administrative tasks, management duties, and streamlines communication to groups or individuals, resulting in a harmonious community experience.
            </p>
            <p className="text-xl leading-loose text-gray-800 my-5">
                Shift away from manual and time comsuming physical contact and administrative processes that cuse repetitive tasks, which frustrate organizations, administrators, and community members.
            </p>
            <p className="text-xl leading-loose text-gray-800 my-5">
                Ciergio promotes more contact-less activities while ensuring more direct communication and better monitoring also with our user friendly “Share-and-Go” features. This also supports community safety especially vital during the “New Normal” to help ensure community-safety!
            </p>
        </div>
        <div className="lg:w-1/2 md:w-full self-center md:p-20 lg:pr-0">
            <img 
                className="mx-auto"
                src={require("../../../assets/images/img-message.png")}
                alt=""
                width="90%"
                />
        </div>
      </div>

}